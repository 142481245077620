import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Bankstelle = () => import('@/entities/bankstelle/bankstelle.vue');
// prettier-ignore
const BankstelleUpdate = () => import('@/entities/bankstelle/bankstelle-update.vue');
// prettier-ignore
const BankstelleDetails = () => import('@/entities/bankstelle/bankstelle-details.vue');
// prettier-ignore
const Facility = () => import('@/entities/facility/facility.vue');
// prettier-ignore
const FacilityUpdate = () => import('@/entities/facility/facility-update.vue');
// prettier-ignore
const FacilityDetails = () => import('@/entities/facility/facility-details.vue');
// prettier-ignore
const BankService = () => import('@/entities/bank-service/bank-service.vue');
// prettier-ignore
const BankServiceUpdate = () => import('@/entities/bank-service/bank-service-update.vue');
// prettier-ignore
const BankServiceDetails = () => import('@/entities/bank-service/bank-service-details.vue');
// prettier-ignore
const DomainIntent = () => import('@/entities/domain-intent/domain-intent.vue');
// prettier-ignore
const DomainIntentDetails = () => import('@/entities/domain-intent/domain-intent-details.vue');
// prettier-ignore
const DomainIntentAction = () => import('@/entities/domain-intent-action/domain-intent-action.vue');
// prettier-ignore
const DomainIntentActionUpdate = () => import('@/entities/domain-intent-action/domain-intent-action-update.vue');
// prettier-ignore
const DomainIntentActionDetails = () => import('@/entities/domain-intent-action/domain-intent-action-details.vue');
// prettier-ignore
const Conversation = () => import('@/entities/conversation/conversation.vue');
// prettier-ignore
const ConversationDetails = () => import('@/entities/conversation/conversation-details.vue');
// prettier-ignore
const Rating = () => import('@/entities/rating/rating.vue');
// prettier-ignore
const RatingDetails = () => import('@/entities/rating/rating-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'bankstelle',
      name: 'Bankstelle',
      component: Bankstelle,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bankstelle/new',
      name: 'BankstelleCreate',
      component: BankstelleUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bankstelle/:bankstelleId/edit',
      name: 'BankstelleEdit',
      component: BankstelleUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bankstelle/:bankstelleId/view',
      name: 'BankstelleView',
      component: BankstelleDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'facility',
      name: 'Facility',
      component: Facility,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'facility/new',
      name: 'FacilityCreate',
      component: FacilityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'facility/:facilityId/edit',
      name: 'FacilityEdit',
      component: FacilityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'facility/:facilityId/view',
      name: 'FacilityView',
      component: FacilityDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bank-service',
      name: 'BankService',
      component: BankService,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bank-service/new',
      name: 'BankServiceCreate',
      component: BankServiceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bank-service/:bankServiceId/edit',
      name: 'BankServiceEdit',
      component: BankServiceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bank-service/:bankServiceId/view',
      name: 'BankServiceView',
      component: BankServiceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/domain-intent',
      name: 'DomainIntent',
      component: DomainIntent,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/domain-intent/:domainIntentId/view',
      name: 'DomainIntentView',
      component: DomainIntentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'domain-intent-action',
      name: 'DomainIntentAction',
      component: DomainIntentAction,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'domain-intent-action/new',
      name: 'DomainIntentActionCreate',
      component: DomainIntentActionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'domain-intent-action/:domainIntentActionId/edit',
      name: 'DomainIntentActionEdit',
      component: DomainIntentActionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'domain-intent-action/:domainIntentActionId/view',
      name: 'DomainIntentActionView',
      component: DomainIntentActionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/domain-intent',
      name: 'DomainIntent',
      component: DomainIntent,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/domain-intent/:domainIntentId/view',
      name: 'DomainIntentView',
      component: DomainIntentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/domain-intent',
      name: 'DomainIntent',
      component: DomainIntent,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/domain-intent/:domainIntentId/view',
      name: 'DomainIntentView',
      component: DomainIntentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/conversation',
      name: 'Conversation',
      component: Conversation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/conversation/:conversationId/view',
      name: 'ConversationView',
      component: ConversationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/rating',
      name: 'Rating',
      component: Rating,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/rating/:ratingId/view',
      name: 'RatingView',
      component: RatingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/domain-intent',
      name: 'DomainIntent',
      component: DomainIntent,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/domain-intent/:domainIntentId/view',
      name: 'DomainIntentView',
      component: DomainIntentDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/conversation',
      name: 'Conversation',
      component: Conversation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/conversation/:conversationId/view',
      name: 'ConversationView',
      component: ConversationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/rating',
      name: 'Rating',
      component: Rating,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/rating/:ratingId/view',
      name: 'RatingView',
      component: RatingDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
